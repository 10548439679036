import { createStore, useStore } from "zustand";
import { createContext, useState, useContext } from "react";
import { persist, createJSONStorage } from 'zustand/middleware'
import C from './config.js';
import { post } from "./fetch.js";

const api = C.projects.core.api
const AuthStoreContext = createContext(null);

const authStore = (set, get) => ({
    user: {},
    googleLogin: async (googleCred) => {
        if (googleCred) {
            const { credential } = googleCred;
            const isValid = await fetch(`${api}/auth/createGoogleToken`, {
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': credential
                },
                method: 'GET'
            });
            const isvalidJson = await isValid.json();
            set((state) => ({
                user: { ...state.user, ...{ google: isvalidJson } },
            }));
        } else {
            set((state) => ({
                user: { ...state.user, ...{ google: null } },
            }));
        }
    },
    dashboardLogin: async (dashboardCred) => {
        if (dashboardCred) {
            const { email, password } = dashboardCred;
            const jsonResponse = await post('auth/dashboardLogin', { email, password })
            jsonResponse.email = email;
            if  (jsonResponse.token) {
                set((state) => ({
                    user: { ...state.user, ...{ dashboard: jsonResponse } },
                }));
            } else {
                set((state) => ({
                    user: { ...state.user, ...{ dashboard: null } },
                }));
            }
        }
        
    },
    demandLogin: async (demandCred) => {
        if (demandCred) {
            const { email, password } = demandCred;
            const jsonResponse = await post('auth/demandLogin', { email, password })
            jsonResponse.email = email;
            if  (jsonResponse.token) {
                set((state) => ({
                    user: { ...state.user, ...{ demand: jsonResponse } },
                }));
            } else {
                set((state) => ({
                    user: { ...state.user, ...{ demand: null } },
                }));
            }
        }
    },
    customLogout: (custom) => {
        set((state) => ({
            user: { ...state.user, ...{ [custom]: null } },
        }));
    },
    logout: () => {
        set(() => ({
            user: null,
        }));
    },
});

const persistedStore = persist(authStore, {
    name: 'auth-storage', // name of the item in the storage (must be unique)
    storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
})

// react context provider
export const AuthStoreProvider = ({ children }) => {
    const [store] = useState(() => createStore(persistedStore))
    return (
        <AuthStoreContext.Provider value={store}>
            {children}
        </AuthStoreContext.Provider>
    )
}

// component React Hook
export const useAuthStore = (selector) => {
    const store = useContext(AuthStoreContext)
    if (!store) {
        throw new Error('Missing AuthStoreContext')
    }
    return useStore(store, selector)
}

// store interface
export const AuthStore = {
    user: (type) => {
        const usr = useAuthStore((state) => state.user);
        if (!type) return usr;
        if (!usr || (type && !usr[type])) return null;
        if (usr.google) {
            if (usr.google.expires && new Date().getTime() > usr.google.expires){
                usr.google = null;
            }
            if (type && type == 'google') return usr.google;
        }
        if (usr.dashboard) {
            if (usr.dashboard.expires && new Date().getTime() > usr.dashboard.expires){
                usr.dashboard = null;
            } 
            if (type && type == 'dashboard') return usr.dashboard;
        }
        if (usr.pipeline) {
            if (usr.pipeline.expires && new Date().getTime() > usr.pipeline.expires){
                usr.pipeline = null;
            } 
            if (type && type == 'pipeline') return usr.pipeline;
        }
        if (usr.demand) {
            if (usr.demand.expires && new Date().getTime() > usr.demand.expires){
                usr.demand = null;
            } 
            if (type && type == 'demand') return usr.demand;
        }
        return usr;
    }
}

export default useAuthStore;