import C from './config.js';

// store interface
const getToken = (type) => {
    const storedUser = sessionStorage.getItem('auth-storage');
    const data = JSON.parse(storedUser).state.user;
    if (!data) return null;
    let res;
    switch (type) {
        case 'dashboard':
            res = data?.dashboard?.token;
            break;
        case 'google':
            res = data?.google?.token;
            break;
    }
    return res;
}

export function appendProtocol(url) {
    return url.includes('http') ? url : `${C.projects.core.api}/${url}`;
}

export async function requestWithAuth(uri, method, body, context, extraOptions) {
    function getBody(){
        if(method === 'GET' || method == 'DELETE') return null;
        if(extraOptions && extraOptions.form) return body;
        return JSON.stringify(body)
    }
    const finalUrl = `${appendProtocol(uri)}`;
    const queryOptions = {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Google-Authorization': getToken(context)
        },
        method,
        body: getBody(),
    };
    if (extraOptions && extraOptions.form) {
        delete queryOptions.headers['Content-Type']
        delete queryOptions.headers.accept;
    }
    const res = await fetch(finalUrl, queryOptions)
    if (res.status < 400) {
        let resJson = await res.json();
        return resJson;
    } else {
        const err = await res.json();
        console.log('Error on request', err)
        window.toast('Error on request', err);
        return null;
    }
}

const dashboardJwtSections = [
    'dashboard',
    '/statistics/v1',
    'stats',
    'signature'
];

function getHeaders(url, token) {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    // browser
    if (typeof window !== "undefined") {
        if (url) {
            if (dashboardJwtSections.some(e => url.includes(e))) {
                const token = getToken('dashboard');
                if (token) headers.Authorization = `Bearer ${token}`;
            }
            if (url.includes('jobs')) {
                const token = getToken('google');
                if (token) headers.Authorization = `Bearer ${token}`;
            }
        }
        if (token) headers.Authorization = `Bearer ${token}`;
    } else {
        // Backend case
        if (token) {
            headers.Authorization = token;
            headers['AddApptr-Auth-JWT'] = token;
        }
    }
    return headers;
}

export const parseJson = async response => {
    if (!response.clone) return response;
    try {
        const json = await response.clone().json();
        return json;
    } catch (err) {
        const text = await response.clone().text();
        return text
    }
}

export const handleError = function (err) {
    console.warn('Error on fetch', err);
    return { json: () => [] };
};

export async function get(url, token, noParse) {
    const finalUrl = `${appendProtocol(url)}`;
    const response = await fetch(finalUrl, {
        method: "get",
        headers: getHeaders(url, token),
    }).catch(handleError);
    if (noParse) return response;
    const jsonResponse = await parseJson(response);
    return jsonResponse;
}

export async function post(url, data, redirect, debug, noJson) {
    const finalUrl = `${appendProtocol(url)}`;
    const fetchOptions = {
        method: "post",
        headers: getHeaders(url),
        //make sure to serialize your JSON body
        body: JSON.stringify(data)
    };

    if (url.includes('dashboard')) fetchOptions.referer = C.projects.dashboard.prod.frontend;
    if (debug) console.info('fetching:', url);
    if (redirect) fetchOptions.redirect = 'follow';
    const response = await fetch(finalUrl, fetchOptions).catch(handleError);
    if (noJson) return response;
    const jsonResponse = await parseJson(response);
    return jsonResponse;
}
