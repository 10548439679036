import React, { useState, useEffect } from 'react';
import { JsonEditor } from 'json-edit-react'
import { AuthStore } from '../store.js';
import { requestWithAuth } from '../fetch.js';

const AdminConf = (props) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
        read()
    }, []);

    async function read() {
        let resJson = await requestWithAuth('admin/config', 'GET', null, 'google')
        setSelected(resJson[0])
    }

    async function update(obj) {
        obj.newData._id = selected._id;
        await requestWithAuth('admin/config', 'PUT', obj.newData, 'google')
        window.toast("Database updated");
    }

    async function refresh() {
        await requestWithAuth('admin/refresh', 'GET', null, 'google')
        window.toast("Database updated");
    }

    const editableData = { ...selected };
    delete editableData._id;
    delete editableData.__v;

    return (<div>
        <pre>
            <JsonEditor
                data={editableData}
                onUpdate={update}
                maxWidth={'100%'}
            />
        </pre>
        <button type="button" className="btn btn-secondary" data-mdb-dismiss="modal" onClick={() => refresh()}>
            Refresh Database
        </button>
    </div>)
}

const Admin = (props) => {
    return (
        <AdminConf user={props.user} token={props.user.token} />
    )
};

const Wrapper = () => {
    const user = AuthStore.user('google');
    return (user ? <Admin user={user} /> : null);
}

export default Wrapper