const useLocalBackend = false;
const jsonLog = false;

export default {
    authSessionName: 'oAuthToken',
    sessionName: 'CoreCookie',
    googleSessionName: 'GoogleCookie',
    expireTime: 1000 * 60 * 60 * 24,
    sessionName: 'gravite-tool-cred',
    emailBanner: true,
    rtbName: 'RtbCookie',
    useLocalBackend,
    jsonLog,
    getPdfName: (obj) => {
        let price = obj.entries.reduce((accum, item) => accum + item.amount, 0);
        let d = new Date().toLocaleDateString('en-CA', { timeZone: 'UTC' });
        let res = d.replaceAll('-', ' ');
        res += ` ER ${obj.name} ${price}.pdf`
        return res;
    },
    getDate: (newDate, short) => {
        if (short) new Date(newDate || null).toLocaleDateString('en-CA', { timeZone: 'UTC' })
    },
    clientId: "19429900960-3h660gsouketvcd5vjttg1a3ov0q1qth.apps.googleusercontent.com",
    versionScrapperText: 'version',
    versionOffset: 46,
    secretKey: 'asjnfasoimsamkmvxzc',
    projects: {
        linkedin: {
            icon: 'https://pluspng.com/img-png/linkedin-png-linkedin-icon-1600.png'
        },
        gravite: {
            logo:'https://dashboard.gravite.net/media/brand/icon.svg',
            icon:'https://i.imgur.com/s5G2GQr.png'
        },
        google: {
            tokenInfo: 'https://oauth2.googleapis.com/tokeninfo?id_token='
        },
        bitbucket: {
            url: 'https://bitbucket.org/',
            slug: '/pipelines/results/',
            commitSlug: '/commits/'
        },
        db: 'http://api-dev03.aatkit.com:3030/?server=api-dev03.aatkit.com',
        core: {
            api: useLocalBackend ? 'http://localhost:4001/core' : 'https://api-dev03.aatkit.com/core',
            client: useLocalBackend ? 'http://localhost:3001' : 'https://api-dev03.aatkit.com',
            database: useLocalBackend ? 'mongodb://api-dev03.aatkit.com:27017/gravite' : 'mongodb://mongodb:27017/gravite',
        },
        backendApi: {
            login: 'https://api.aatkit.com/auth/v1/login',
            pipelineBuilder: 'https://api.aatkit.com/pipeline-build/v1/pipeline/run?pipeline=',
        },
        config: {
            projectList: 'https://api.aatkit.com/config/v1/project-list',
        },
        dashboard: {
            test: {
                frontend: 'https://dashboard-test.aatkit.com/',
                backend: 'https://dashboard-test-middleware.aatkit.com'
            },
            "pre-prod": {
                frontend: 'https://dashboard-stage.aatkit.com/',
                backend: 'https://dashboard-stage-middleware.aatkit.com'
            },
            prod: {
                frontend: 'https://dashboard.gravite.net/',
                api: 'https://dashboard-middleware.gravite.net'
            }
        },
        taskScheduler: {
            prod: 'https://scheduler-dev.aatkit.com/api',
            dev: 'https://task-scheduler-dev.aatkit.com/api'
        },
        notificationService: {
            prod: 'https://notification-service.aatkit.com/api/v1/swagger/',
            dev: 'https://notification-service.aatkit.com/api/v1/swagger/',
            api: 'https://notification-service.aatkit.com/api/v1/notifications',
            test: 'https://api.aatkit.com/notification/v1/'
        },
        mayoPlot: {
            prod: 'https://mayoplot-mayo2.aatkit.com/api',
            dev: 'https://mayoplot-dev.aatkit.com/api'
        },
        portainer: {
            dev: 'https://api-dev03.aatkit.com:9443/#!/auth'
        },
        testApi: {
            prod: 'https://test-dev03.aatkit.com'
        },
        rtbApi: {
            login: 'https://dashboard-rtb.gravite.net/api/create_token',
            api: 'https://dashboard-rtb.gravite.net/api'
        },
        expenses: {
            active: true
        },
        oauth: {
            // test
            // api: 'http://localhost:8082'
            // prod
            api: 'http://api-dev03.aatkit.com:8082',
            clientId: 'examplePocClientId',
            clientSession: 'examplePocSessionId',
        },
        logger: 'http://api-dev03.aatkit.com:8080',
        analytics: 'https://analytics-dev03.aatkit.com',
        coreOverview: 'https://docs.google.com/spreadsheets/d/1WU6jOx06cMaaUKJpcs4K2ynBM4EKmWbwKzez2tOY4PE/edit#gid=0',
    }
}