import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { requestWithAuth } from '../fetch.js';
import Loader from "./Loader.js";
import { AuthLoginModal } from '../auth.js';
import C from '../config.js'

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

const attrMapper = [
    { label: 'ssp_id', value: 'ssp_id' },
    { label: 'company_ssp', value: 'company_ssp' },
    { label: 'dsp_id', value: 'dsp_id' },
    { label: 'company_dsp', value: 'company_dsp' },
    { label: 'country', value: 'country' },
    { label: 'traffic', value: 'traffic' },
    { label: 'ad_format', value: 'ad_format' },
    { label: 'domain', value: 'domain' },
    { label: 'size', value: 'size' },
    { label: 'os', value: 'os' },
    { label: 'crid', value: 'crid' },
    { label: 'consent_regulation_compliance', value: 'consent_regulation_compliance' },
    { label: 'ssp_name', value: 'ssp_name' },
    { label: 'dsp_name', value: 'dsp_name' },
]

const metricsMapper = [
    { label: 'ssp_requests', value: 'ssp_requests' },
    { label: 'bid_requests', value: 'bid_requests' },
    { label: 'bid_responses', value: 'bid_responses' },
    { label: 'dsp_wins', value: 'dsp_wins' },
    { label: 'ssp_wins', value: 'ssp_wins' },
    { label: 'impressions', value: 'impressions' },
    { label: 'clicks', value: 'clicks' },
    { label: 'ssp_revenue', value: 'ssp_revenue' },
    { label: 'ssp_ecpm', value: 'ssp_ecpm' },
    { label: 'avg_ssp_bid_price', value: 'avg_ssp_bid_price' },
    { label: 'avg_ssp_bid_floor', value: 'avg_ssp_bid_floor' },
    { label: 'avg_dsp_bid_price', value: 'avg_dsp_bid_price' },
    { label: 'avg_dsp_bid_floor', value: 'avg_dsp_bid_floor' },
    { label: 'dsp_spend', value: 'dsp_spend' },
    { label: 'dsp_ecpm', value: 'dsp_ecpm' },
    { label: 'profit', value: 'profit' },
    { label: 'bid_rate', value: 'bid_rate' },
]

const granularityMapper = [
    { label: 'month', value: 'month' },
    { label: 'day', value: 'day' },
    { label: 'total', value: "total" }
]

const Checkbox = ({ label, value, onChange, disabled, array }) => {
    const arrCheck = (array) ? array.includes(value) : value;
    return (
        <label style={{ margin: '0 1%' }}>
            <input className="form-check-input" type="checkbox" value={value} checked={array ? arrCheck : undefined} onChange={onChange} disabled={disabled} />
            {' ' + label}
        </label>
    );
};
const isNumeric = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);
function getColorFromPercentage(percentage) {
    // Ensure percentage is within bounds
    percentage = Math.max(-100, Math.min(100, percentage));
    // Convert percentage to a hue value between 0 (red) and 120 (green)
    // -100% maps to 0 (red), 0% maps to 60 (yellow), 100% maps to 120 (green)
    const hue = (percentage + 100) * 0.6;
    // Use a saturation and lightness of 100% for vivid colors
    return `hsl(${hue}, 100%, 50%)`;
}

const BluePages = (props) => {
    const [apiQuery, setApiQuery] = useState(null);
    const [selectedKpi, setSelectedKpi] = useState('ssp_revenue');
    const [selectedDim, setSelectedDim] = useState(['company_dsp']);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [filters, setFilters] = useState([0]);
    const [metrics, setMetrics] = useState(metricsMapper);
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [tableRows, setTableRows] = useState([]);
    const [originalHeaders, setOriginalHeaders] = useState([])
    const [tableValues, setTableValues] = useState([]);
    const [originalValues, setOriginalValues] = useState([]);
    const [processData, setProcessData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [processedNameFilter, setProcessedNameFilter] = useState('')
    const [hideGB, setHideGB] = useState(true)
    const [kpiList, setKpiList] = useState([]);
    const [rawData, setRawData] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [activeInfoTab, setActiveInfoTab] = useState('country');
    const [infoModalDate, setInfoModalDate] = useState();
    const [infoModalPub, setInfoModalPub] = useState(null);
    const [lastFetched, setLastFetched] = useState(null);
    const [granularity, setGranularity] = useState('day');
    const token = props.user.token;

    async function fetchStats() {
        setLoading(true);
        setLastFetched(new Date())
        const statsResult = await requestWithAuth('reports/demandStats', 'POST', { reportUrl: apiQuery }, 'google');
        FilterData(statsResult)
    }

    useEffect(() => {
        createQuery();
    }, [selectedDim, token, startDate, endDate, granularity, selectedFilter, metrics])

    useEffect(() => {
        FilterData(rawData);
    }, [selectedKpi])

    useEffect(() => {
        modalData ? showExtraInfo(infoModalDate) : null;
    }, [activeInfoTab])

    async function AddSelector(dim) {
        if (dim && dim.length) {
            const res = [];
            dim.forEach(a => res.push(a.value))
            setSelectedDim(res)
        }
    }

    function FilterData(statsResult) {
        if (!statsResult) return;
        if (statsResult.message && statsResult.message.includes('Token invalid')) return;

        const statsResultRawData = statsResult.data;

        if (selectedDim.length > 1) {
            // sort output
            const objectOrder = {};
            selectedDim.forEach(d => objectOrder[d] = null);
            objectOrder.date = null;
            const addObjectResource = Object.assign(objectOrder, statsResultRawData[0]);
            const dataSorted = [];
            statsResultRawData.forEach(r => {
                const sortedRow = Object.assign(objectOrder, r);
                dataSorted.push(Object.values(sortedRow));
            })
            setTableRows(Object.keys(addObjectResource));
            setOriginalHeaders(Object.keys(addObjectResource))
            setTableValues(dataSorted);
            setOriginalValues(dataSorted);
            setLoading(false);
        } else {
            statsResultRawData.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.date) - new Date(b.date);
            });

            const dspList = [];
            // extract all dsps
            const unique = statsResultRawData.filter((obj, index) =>
                statsResultRawData.findIndex((item) => item[selectedDim] === obj[selectedDim]) === index
            );
            unique.forEach(a => dspList.push(a[selectedDim]))
            const headers = [selectedDim];
            const data = [];

            dspList.forEach((dsp, i) => {
                const filteredDateAsRows = statsResultRawData.filter(row => row[selectedDim] == dsp);
                if (i == 0) filteredDateAsRows.forEach(p => headers.push(p.date))
                let row = [dsp];
                filteredDateAsRows.forEach(p => row.push(p[selectedKpi]))
                data.push(row)
            })
            const kpiArr = [];
            Object.keys(statsResultRawData[0]).map(i => kpiArr.push({ value: i, label: i }))
            setRawData(statsResult)
            setKpiList(kpiArr)
            data.unshift(headers);
            setTableRows(headers)
            setTableValues(data)
            setLoading(false);
        }
    }

    function createQuery() {
        let finalQuery = token + '/adx-report';
        finalQuery += '?attribute[]=' + selectedDim[0];
        if (selectedDim.length > 1) {
            selectedDim.slice(1).forEach((element, index) => {
                finalQuery += '&attribute[]=' + element;
            });
        }
        if (selectedFilter.length) {
            selectedFilter.forEach((element, index) => {
                finalQuery += `&filter[${element.key}][]=${element.value}`;
            });
        }
        if (metrics.length) {
            metrics.forEach(m => {
                finalQuery += `&metric[]=${m.value}`
            })
        }
        finalQuery += '&from=' + startDate + '&to=' + endDate;
        finalQuery += '&day_group=' + granularity;
        finalQuery += '&limit=10000'
        setApiQuery(finalQuery);
    }

    async function showExtraInfo(infoDate, pub) {
        const selectedPub = pub || infoModalPub;
        let newQuery = apiQuery;
        newQuery = newQuery.replace('company_dsp', 'company_dsp&attribute[]=' + activeInfoTab);
        newQuery = newQuery.replace(startDate, infoDate);
        newQuery = newQuery.replace(endDate, infoDate);
        newQuery += '&filter[company_dsp][]=' + selectedPub;
        const statsResult = await requestWithAuth('reports/demandStats', 'POST', { reportUrl: newQuery }, 'google');
        const res = statsResult.data;
        // sort by dsp_spend
        res.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return b.dsp_spend - a.dsp_spend;
        });
        setModalData(res.slice(0, 10))
        setInfoModalDate(infoDate)
        setInfoModalPub(selectedPub)
    }

    function renderBlock(data) {
        if (!data) return;
        // extract first two rows (title and dim)
        let subarray = data;
        if (processedNameFilter) subarray = subarray.filter(a => a[0].toLowerCase().includes(processedNameFilter))
        if (hideGB) subarray = subarray.filter(a => !a[0].includes('Google Bidding'))

        // sortedBlock
        const sortedArr = subarray.filter(a => a.filter(Number).length).sort((a, b) => {
            if (a.filter(Number).length > 0 && b.filter(Number).length > 0) {
                // filter to get just numbers
                const filteredArrA = a.filter(Number);
                if (filteredArrA.length) {
                    // sum A arr
                    const sumA = filteredArrA.map(el => parseFloat(el)).reduce((a, b) => a + b);
                    // filter to get just numbers
                    const filteredArrB = b.filter(Number);
                    // sum A arr
                    const sumB = filteredArrB.map(el => parseFloat(el)).reduce((a, b) => a + b);
                    return sumB - sumA;
                }
            }
        });

        // headers
        const headers = data[0];

        // filling totals
        const length = headers.length;
        const totalRows = ['totals'];
        for (let i = 1; i < length; i++) { totalRows.push(0); }

        sortedArr.forEach((e, index) => {
            // headers has the whole length - title and total 
            for (let i = 1; i < length; i++) {
                if (e[i] === undefined || e[i] === NaN || !e[i]) {
                    sortedArr[index][i] = 0;
                } else {
                    totalRows[i] = parseFloat(totalRows[i]) + parseFloat(e[i]);
                }
            }
        })

        const abstotal = [...totalRows];
        abstotal.shift();
        totalRows.push(abstotal.reduce((partialSum, a) => partialSum + a, 0))

        return <div>
            <table className='table tbl table-striped table-bordered'>
                <thead>
                    <tr>{headers.map(h => <th key={h}>{h}</th>)}</tr>
                </thead>
                <tbody>
                    <tr className='title'>{totalRows.map(h => <td key={h}>{isNumeric(h) ? h.toFixed(2) : h}</td>)}</tr>
                    {sortedArr.map((value, index) => { //Rows
                        let average = 0;
                        // calculate avg
                        if (value && value.filter(Number).length > 0) {
                            const filteredArr = value.filter(Number);
                            average = filteredArr.map(el => parseFloat(el)).reduce((a, b) => a + b) / filteredArr.length;
                        }
                        //skipping GAM/MCM
                        const totalsColumn = (value[0] === 'Column Totals');

                        let total = 0;

                        return (
                            <tr key={index} className={totalsColumn ? 'title' : ''}>
                                {value.map((val, i) => { //Columns
                                    let bg = 'none';
                                    let finalValue = val
                                    const delta = 100 * ((val - average) / Math.abs(average));
                                    if (isNumeric(val)) {
                                        bg = getColorFromPercentage(delta)
                                        finalValue = parseFloat(val).toFixed(2).toLocaleString();
                                        total += parseFloat(val);
                                        return <td key={i} className='test' onClick={() => showExtraInfo(sortedArr[0][i], value[0])} style={{ backgroundColor: bg }} key={value[0] + i + total}>{finalValue}</td>;
                                    } else {
                                        // filter per account
                                        return <td className='test' key={value[0] + i + total}>{finalValue}</td>;
                                    }
                                })}
                                {total > 0 ? <td className='test'>{total.toFixed(2)}</td> : ''}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    }

    function analyzeCSVData() {
        return (
            <div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Filter" value={processedNameFilter} onChange={(e) => setProcessedNameFilter(e.target.value)} />
                </div>
                <div className="row justify-content-start">
                    <div className="col-2">
                        <ul className="nav flex-column">
                            {kpiList.map(k => <li key={k.value} className="nav-item" onClick={() => setSelectedKpi(k.value)}>
                                <a className="nav-link" style={{ padding: '8px 5px', fontSize: '14px', background: selectedKpi === k.value ? 'whitesmoke' : 'white' }} href="#">{k.label}</a>
                            </li>)}
                        </ul>
                    </div>
                    <div className="col-10">
                        {renderBlock(tableValues)}
                    </div>
                </div>
            </div>
        )
    }

    function baseSelect({ defaultValue, options, onChange, isMulti, marginStyle = '1% 0', onReset }) {
        return (
            <>
                {onReset ?
                    <button style={{ float: 'right' }} type="button" className="btn btn-link" onClick={onReset}>Reset</button>
                    : null}
                <Select
                    defaultValue={defaultValue}
                    name="colors"
                    isMulti={isMulti}
                    options={options}
                    styles={{
                        container: (base) => ({
                            ...base,
                            width: '100%',
                            margin: marginStyle
                        }),
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={e => onChange(e)}
                />
            </>
        )
    }

    function FilterWrapper(filterArr, onFiltered) {
        let filter = {};
        function setNewfilter(e) {
            filter.value = e.target.value
            onFiltered(filter)
        }

        // create selectr key value
        const values = [];
        filterArr.forEach(a => values.push({ label: a, value: a }))
        return (
            <div style={{ display: 'inline-flex' }}>
                <div className="input-group">
                    <div className="input-group-btn bs-dropdown-to-select-group">
                        {baseSelect({
                            defaultValue: null,
                            options: values,
                            onChange: e => filter.key = e.value,
                            marginStyle: '0',
                        })}

                    </div>
                    <input type="text" style={{ height: 'fit-content' }} className="form-control" name="text" onBlur={e => setNewfilter(e)} />
                </div>
            </div>
        )
    }

    function AddFilterRow() {
        return FilterWrapper(selectedDim, (filtered) => {
            if (filtered.value && filtered.key) {
                setSelectedFilter([...selectedFilter, filtered])
                setFilters([...filters, [0]])
            }
        })
    }

    function AddRawfilter() {
        return FilterWrapper(tableRows, (filtered) => {
            if (filtered.value == '') setTableValues(originalValues);
            if (filtered.value && filtered.key) {
                const indexToFilter = tableRows.indexOf(filtered.key);
                const newValues = tableValues.filter(row => row[indexToFilter].includes(filtered.value));
                setTableValues(newValues);
            }
        })
    }

    function extraSelectorsMulti() {
        function onReset() {
            setTableRows(originalHeaders);
            setTableValues(originalValues)
        }
        if (selectedDim.length > 1) {
            return <>
                <button type="button" className="btn btn-link" onClick={() => onReset()}>Reset</button>
                <br />
                {AddRawfilter()}
            </>
        } else {
            return null
        }
    }

    function csvTable() {
        return (
            <div>
                {extraSelectorsMulti()}
                <table className='table tbl table-striped table-bordered'>
                    <thead><tr>{tableRows.map((rows, index) => <th className='header table-warning' key={index}>{rows}</th>)}</tr></thead>
                    <tbody>{tableValues.map((value, index) => <tr key={index}>{value.map((val, i) => <td key={i}>{val}</td>)}</tr>)}</tbody>
                </table>
            </div>
        );
    }

    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <div>
                <div className="row align-items-start" style={{ display: 'inline-flex', margin: '2%' }}>
                    <div className="col">
                        Granularity
                        {baseSelect({
                            defaultValue: granularityMapper[1],
                            options: granularityMapper,
                            onChange: (e) => setGranularity(e.value)
                        })}

                        Group By
                        {baseSelect({
                            defaultValue: attrMapper[3],
                            options: attrMapper,
                            isMulti: true,
                            onChange: (e) => AddSelector(e)
                        })}

                        Metrics
                        {baseSelect({
                            defaultValue: metrics,
                            options: metricsMapper,
                            isMulti: true,
                            onChange: (e) => setMetrics(e)
                        })}

                        Filters
                        <br />
                        <div style={{ overflow: 'visisble' }}>
                            <button type="button" className="btn btn-link" onClick={() => setSelectedFilter([])}>Reset</button>
                            {filters.map((a, index) => <div key={index}>{AddFilterRow()}</div>)}
                        </div>
                        <br />
                        <label htmlFor="start">From-To</label>
                        <br />
                        <input style={{ float: 'left' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        <input type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                    </div>
                    <div className="col" style={{ display: "grid" }}>
                        <Checkbox
                            label="Show API Query"
                            value={showQuery}
                            onChange={() => setShowQuery(!showQuery)}
                        />
                        <Checkbox
                            label="Include GBidding"
                            value={hideGB}
                            onChange={() => setHideGB(!hideGB)}
                        />
                    </div>

                </div>
                <br />
                {showQuery && <div className="form-group">
                    <label htmlFor="usr">Paste Query:</label>
                    <input type="text" className="form-control" id="usr" onChange={(e) => setApiQuery(e.target.value)} />
                    <br />
                    <div className="row" style={{ paddingTop: '1%' }}>
                        <pre>{C.projects.rtbApi.api + '/' + apiQuery}</pre>
                    </div>
                </div>}
                <div>
                    <button type="button" className="btn btn-primary" onClick={fetchStats}>Run</button>
                </div>
                <br />
                {lastFetched ? <span>Last sync: {lastFetched.toLocaleDateString()}: {lastFetched.toLocaleTimeString()}</span> : null}
                {tableRows && tableRows.length > 0 && <Checkbox
                    label="Process Result"
                    value={processData}
                    onChange={() => setProcessData(!processData)}
                />}
                <button style={{ margin: '2px', float: 'right' }} type="button" onClick={() => fetchStats()} className="btn btn-primary"><i className="bi bi-arrow-clockwise" /> </button>
                {processData && !loading && analyzeCSVData()}
                <br />
                {tableRows && !loading && !processData && csvTable()}
                {loading ? <Loader /> : null}

            </div>
        </div>
    )
};

const Wrapper = () => {
    return (<AuthLoginModal target='demand' style='rtb' open={true} fallback={(user) => <BluePages user={user} />} />);
}

export default Wrapper