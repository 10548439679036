import React, { useEffect, useState } from 'react';
import Expense from './Expense.js';
import { EditModal, ConfirmModal } from './Modals.js';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Checkbox } from '../../helpers.js';
import { requestWithAuth } from '../../fetch.js';

// mkConfig merges your options with the defaults
// and returns WithDefaults<ConfigOptions>
const csvConfig = mkConfig({ useKeysAsHeaders: true });

function ExpenseAdmin(props) {
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);
    const [modalSelected, setModalSelected] = useState();
    const [supervisorList, setSupervisorList] = useState();
    const [showGoogleModal, setShowGoogleModal] = useState();

    useEffect(() => {
        fetchFilterExpensesList();
    }, [])

    async function update(obj) {
        const createRes = await requestWithAuth(`expenses/${obj._id}`, 'PUT', obj, 'google');
        window.toast(createRes.status == 200 ? "Expense Report Updated" : "Unauthorized access.");
    }

    async function sendFinance(obj) {
        await setSelected(obj)
        const newObj = obj;
        obj.finance_ack = true;
        await update(obj);
        await props.generatePDF(newObj.name, newObj.entries, true, true, (pdf) => {
            newObj.pdf64 = pdf;
            const createRes = requestWithAuth('expenses/finance', 'POST', newObj, 'google');
            window.toast(createRes.status == 200 ? "Expense Report sent to Finance" : "Unauthorized access.");
        })
    }

    async function del(id) {
        await requestWithAuth(`expenses/${id}`, 'DELETE', null, 'google');
        await fetchFilterExpensesList();
        window.toast("Expense Report Deleted");
    }

    async function fetchFilterExpensesList() {
        let loadedList = await props.read();
        if (props.view === 'draft') {
            loadedList = loadedList.filter(f => f.email === props.user.email && f.draft)
        }
        if (props.view === 'history') {
            loadedList = loadedList.filter(f => f.email === props.user.email && !f.draft)
        }
        if (props.view === 'supervisor') {
            loadedList = loadedList.filter(f => f.supervisorAssigned === props.user.email && f.om_checked)
        }
        if (props.view === 'admin') {
            loadedList = loadedList.filter(f => !f.draft)
            const supListRaw = await props.read(true)
            setSupervisorList(supListRaw)
        }
        setList(loadedList)
    }

    async function signedAndDone(signedObj) {
        await update(signedObj);
        await fetchFilterExpensesList();
        window.toast("Expense Report Updated");
    }

    function selEdit(el) {
        setModalSelected(el)
        setShowModal(true);
    }

    function selSupervisor(el) {
        setModalSelected(el)
        setShowConfirmModal(true);
    }

    function confirmDel(el) {
        setModalSelected(el)
        setShowDelModal(true);
    }

    function confirmGoogle(el) {
        setModalSelected(el)
        setShowGoogleModal(true);
    }

    function exportCsv() {
        const exportFormat = [...list];
        exportFormat.forEach(l => {
            l.signature = !!l.signature
            l.supervisorSignature = !!l.supervisorSignature
            l.amount = l.entries.reduce((accum, item) => accum + item.amount, 0);
            delete l.entries
        });
        const csv = generateCsv(csvConfig)(exportFormat);
        download(csvConfig)(csv)
    }

    function expensesTable() {
        return <div className='row'>
            {showModal && <EditModal show={showModal} selected={modalSelected} onClose={(updateObj) => {
                if (updateObj) update(updateObj);
                setShowModal(false)
            }} />}
            {showDelModal && <ConfirmModal delete={true} show={showDelModal} selected={modalSelected} onClose={(idDel) => {
                if (idDel) del(idDel);
                setShowDelModal(false)
            }} />}
            {props.view === 'admin' && showConfirmModal && <ConfirmModal assign={true} show={showConfirmModal} selected={modalSelected} onClose={(updateObj) => {
                if (updateObj) update(updateObj);
                setShowConfirmModal(false);
            }} />}
            {props.view === 'admin' && showGoogleModal && <ConfirmModal google={true} assign={true} show={showGoogleModal} selected={modalSelected} onClose={(updateObj) => {
                if (updateObj) update(updateObj);
                setShowGoogleModal(false);
            }} />}
            <div className="App tableFixHead">
                <button type="button" style={{ margin: '2% 0' }} className="btn btn-warning" onClick={exportCsv}>Export</button>
                <table className="table table-striped table-hover" data-toggle="table" data-resizable="true">
                    <thead className='table-dark'>
                        <tr>
                            <th>Date</th>
                            <th>Id</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Department</th>
                            <th>Purpose</th>
                            <th>Signed by employee</th>
                            <th>Supervisor</th>
                            <th>Signed by supervisor</th>
                            <th>Signed by supervisor on</th>
                            <th>Amount</th>
                            <th>External</th>
                            <th className='col-md-1'>Approved OM</th>
                            {props.view === 'admin' ? <th>Sent to Finance</th> : null}
                            {props.view === 'admin' ? <th>Upload GDrive</th> : null}

                            {(props.view === 'draft' || props.view === 'admin') ? <th style={{ width: '260px' }}>Options</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(el => (
                            <tr className={`expenseRow ${selected?.id === el.id ? 'selected' : ''}`} key={el.id} style={{ height: '3em' }}>
                                <td onClick={() => setSelected(el)}>{(new Date(parseInt(el._id.toString().substring(0, 8), 16) * 1000)).toLocaleDateString('de-DE', { timeZone: 'UTC' })}</td>
                                <td onClick={() => setSelected(el)}>{el.id}</td>
                                <td onClick={() => setSelected(el)}>{el.email}</td>
                                <td onClick={() => setSelected(el)}>{el.name}</td>
                                <td onClick={() => setSelected(el)}>{el.department}</td>
                                <td onClick={() => setSelected(el)}>{el.purpose}</td>
                                <td onClick={() => setSelected(el)}><Checkbox value={!!el.signature} disabled={true} /></td>
                                <td onClick={() => setSelected(el)}>{el.supervisorAssigned}</td>
                                <td onClick={() => setSelected(el)}><Checkbox value={!!el.supervisorSignature} disabled={true} /></td>
                                <td onClick={() => setSelected(el)}>{el.supervisorDate ? (new Date(el.supervisorDate).toLocaleDateString('de-DE', { timeZone: 'UTC' })) : null}</td>
                                <td onClick={() => setSelected(el)}>{el.entries && el.entries.length ? el.entries.reduce((accum, item) => accum + item.amount, 0) : "N/A"}</td>
                                <td onClick={() => setSelected(el)}>{el.entries && el.entries.length ? el.entries.some(it => it.external).toString() : false.toString()}</td>

                                <td className='col-md-1'><Checkbox value={!!el.om_checked} disabled={true} /></td>
                                {props.view === 'admin' ? <td><Checkbox value={!!el.finance_ack} disabled={true} /></td> : null}
                                {props.view === 'admin' ? <td><Checkbox value={!!el.g_drive} /></td> : null}

                                {(props.view === 'draft' || props.view === 'admin') ? <td>
                                    {(props.view === 'admin') && <button style={{ marginRight: '2px' }} type="button" className="btn btn-light" onClick={() => selEdit(el)} title="Edit"><i className="bi bi-pencil-square" /></button>}
                                    {props.view === 'admin' && <button style={{ marginRight: '2px' }} type="button" className="btn btn-warning" onClick={() => selSupervisor(el)} title="Assign supervisor"><i className="bi bi-check-square-fill" /></button>}
                                    {props.view === 'admin' && <button style={{ marginRight: '2px' }} type="button" disabled={!el.supervisorSignature} className="btn btn-success" onClick={() => sendFinance(el)} title="Send to Finance"><i className="bi bi-file-binary" /></button>}
                                    {props.view === 'admin' && <button style={{ marginRight: '2px' }} type="button" disabled={!el.supervisorSignature} className="btn btn-info" onClick={() => confirmGoogle(el)} title="Send to GDrive"><i className="bi bi-google" /></button>}
                                    {(el.draft || props.view === 'admin') && <button style={{ marginRight: '2px' }} type="button" className="btn btn-danger" onClick={() => confirmDel(el)} title="Remove"><i className="bi bi-trash" /></button>}
                                </td> : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    }
    return <div>
        {expensesTable()}
        {selected && <Expense read={props.read} selected={selected} onDraftOrSupervisor={signedAndDone} user={props.user} generatePDF={props.generatePDF} view={props.view} />}
    </div>
}

export default ExpenseAdmin;