import React, { useState } from 'react';
import Loader from './Loader.js'

const Login = (props) => {
    const [show, setShow] = useState(props.open || false);
    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const type = props.type || "primary"
    const text = props.text || "Login"

    async function onLogin() {
        setLoading(true)
        props.onLogin({ email, password })
        setTimeout(() => setLoading(false));
        setShow(false);
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) onLogin();
    };

    const style = {
        gravite: {
            bg: 'linear-gradient(180deg,#404667,#0e1727)',
            footerbg: 'none',
            link: "https://dashboard.gravite.net/",
            linkText: 'Gravite Dashboard'
        },
        rtb: {
            bg: '#000000c2',
            footerbg: '#852fd0',
            link: 'https://dashboard-rtb.gravite.net/',
            linkText: 'Gravite RTB'
        }
    }

    const selectedStyle = (props.style) ? style[props.style] : style.gravite;

    return (
        <>
            {!show && <button type="button" className={"btn btn-" + type} data-mdb-modal-init data-mdb-target="#exampleModal" onClick={handleShow}>
                {props.user ? 'Welcome back' : text}
            </button>}

            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? selectedStyle.bg : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog">
                    {loading ? <Loader /> : null}
                    {!loading ? <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Login</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">

                                <form className="mb-md-5 mt-md-4 pb-5">

                                    <h2 className="mb-2">Login</h2>
                                    <blockquote style={{ margin: '1rem', padding: '1rem', fontStyle: 'italic' }}>Please use <a href={selectedStyle.link} target='_blank'>{selectedStyle.linkText}</a> credentials </blockquote>

                                    <div className="form-outline form-white mb-4">
                                        <input type="email" placeholder='Email' id="typeEmailX" className="form-control form-control-lg" onChange={(e) => setEmail(e.target.value)} />
                                    </div>

                                    <div className="form-outline form-white mb-4">
                                        <input type="password" id="typePasswordX" placeholder='Password' className="form-control form-control-lg" onKeyDown={handleKeypress} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <button type="button" className="btn btn-secondary" data-mdb-dismiss="modal" onClick={onLogin}>
                                        Login
                                    </button>
                                </form>
                            </div>
                        </div>
                        <footer className="modal-footer" style={{ margin: 'auto', background: selectedStyle.footerbg, width: '100%' }}>
                            <a href="https://www.gravite.net/" target="_blank">
                                <img src="https://dashboard.gravite.net/media/brand/icon.svg" alt="Gravite | Dashboard" width="128" />
                            </a>
                        </footer>
                    </div> : null}
                </div>
            </div>

        </>
    )
}

export default Login