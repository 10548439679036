import React, { useEffect, useState } from 'react';
import Login from "./containers/Login.js";
import { GoogleLogin } from '@react-oauth/google';
import useAuthStore, { AuthStore } from './store.js';
import { useNavigate } from "react-router-dom";
import ModalWrapper from './containers/ModalWrapper.js';
import C from './config.js';

export const TopNav = (props) => {
    // store
    const customLogout = useAuthStore((state) => state.customLogout);
    const logout = useAuthStore((state) => state.logout);
    const addGoogleLogin = useAuthStore((state) => state.googleLogin);
    const user = AuthStore.user()
    const navigate = useNavigate();

    async function onLogin(cred) {
        await addGoogleLogin(cred);
        props.onChange(true);
    }

    function onLogout(custom) {
        customLogout(custom);
        props.onChange(false);
    }

    function getUser() {
        const res = {...user};
        if (res?.dashboard?.dashboardSettings) {
            delete res.dashboard.dashboardSettings;
        }
        return res;
    }

    function UserDropdown() {
        const [dropdown, setDropdown] = useState(false);
        return (
            <div className="dropdown">
                <button className="btn btn-warning dropdown-toggle" type="button" onClick={() => setDropdown(!dropdown)}>
                    {user?.google.name ? <>Welcome back {user.name} <img style={{ width: '1.5rem', borderRadius: '2rem' }} src={user?.google.picture} /> </> : null}
                </button>
                <ul className="dropdown-menu" style={{ display: dropdown ? 'block' : 'none' }}>
                    <li><a className="dropdown-item" href="#" style={{ color: !user.google ? 'none' : 'red' }} onClick={() => onLogout('google')}>Google Logout</a></li>
                    <li><a className="dropdown-item" href="#" style={{ color: !user.dashboard ? 'none' : 'red' }} onClick={() => !user.dashboard ? navigate('/dashboardLogin') : onLogout('dashboard')}>{!user.dashboard ? 'Dashboard Login ' : 'Dashboard Logout'}</a></li>
                    <li><a className="dropdown-item" href="#" style={{ color: !user.demand ? 'none' : 'red' }} onClick={() => !user.demand ? navigate('/demandLogin') : onLogout('demand')}>{!user.demand ? 'Rtb/teqBlaze Login' : 'Rtb/teqBlaze Logout'}</a></li>
                    {user ? <li><a className="dropdown-item" href="#" onClick={() => logout()}>Logout</a></li> : null}
                    {user ? <ModalWrapper title="User" buttonTitle='User Data' style='link'>
                        <div>
                            <pre style={{ textAlign: 'justify' }}>
                                User data:
                                {JSON.stringify(getUser(), null, 4)}
                            </pre>
                        </div>
                    </ModalWrapper> : null}
                </ul>
            </div>
        )
    }

    return (
        <nav className="navbar bg-body-tertiary">
            <div className="container-fluid">
                <span className="navbar-brand mb-0 h1">
                    <a href="/" style={{ marginRight: '1%' }}>
                        <img src={`${C.projects.gravite.logo}`} alt="gravite logo" width="120" height="31" />
                    </a>
                    Core Admin Tool (CAT)
                </span>
                <div style={{ float: 'right', display: 'inline-flex' }}>
                    {(!user || !user.google) ? <GoogleLogin auto_select onSuccess={cred => onLogin(cred)} type={user?.given_name ? `icon` : null} onError={(err) => console.log('Login Failed:', err)} /> : <UserDropdown />}
                </div>
            </div>
        </nav>
    );
}

export const AuthLoginModal = (props) => {
    const [logged, setLogged] = useState(null);
    const dashboardLogin = useAuthStore((state) => state.dashboardLogin);
    const demandLogin = useAuthStore((state) => state.demandLogin);
    const user = AuthStore.user();
    const navigate = useNavigate();

    async function onLogin(cred) {
        switch (props.target) {
            case 'dashboard':
                await dashboardLogin(cred);
                if (props.onChange) props.onChange(user.dashboard);
                setLogged(user[props.target]);
                break;
            case 'demand':
                await demandLogin(cred);
                if (props.onChange) props.onChange(user.demand);
                setLogged(user[props.target]);
                break;
        }
    }

    useEffect(() => {
        if (user[props.target]) {
            setLogged(user[props.target]);
        }
    })

    if (logged && props.fallback) {
        return props.fallback(user[props.target])
    }

    if (logged && !props.fallback) {
        return navigate('/')
    }

    return <Login open={props.open || false} text={`${props.target} Login`} style={props.style} onLogin={(creds) => onLogin(creds)} />
}