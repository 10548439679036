import React, { useState } from 'react';
import useWebSocket from 'react-use-websocket';
import ModalWrapper from './ModalWrapper.js';
import C from '../config.js'

const WS_URL = C.projects.core.api.replace('http', 'ws');

function isJson(res) {
    if (!res) return res
    let result = res.toString('utf8');
    try {
        result = JSON.parse(res);
    } catch (e) {
        console.error(e)
        return result;
    }
    return result;
}

function Debug() {
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null);

    useWebSocket(WS_URL, {
        onOpen: () => {
            console.log('WebSocket connection established.');
        },
        onMessage: (msg) => {
            const row = isJson(msg.data)
            const userInfo = isJson(row.userInfo)
            row.id = userInfo.id;
            row.email = userInfo.email;
            row.userInfo = userInfo;
            const newData = [...data];
            newData.push(row);
            setData(newData);
        },
        shouldReconnect: () => true,
    });

    function debugTable() {
        return <div className='row'>
            <div className="App tableFixHead">
                <table className="table table-dark table-striped">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Id</th>
                            <th>email</th>
                            <th>Url</th>
                            <th>Path</th>
                            <th>Response</th>
                            <th>Method</th>
                            <th>Body</th>
                            <th>Host</th>
                            <th>Code</th>
                            <th>User Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.filter(key => key.id === selected).map((res, index) => [
                            <tr key={index} style={{ height: '3em' }}>
                                <td>{new Date().toTimeString()}</td>
                                <td>{res.id}</td>
                                <td>{res.email}</td>
                                <td>{res.url}</td>
                                <td>{res.path}</td>
                                <td><ModalWrapper title="Response" buttonTitle='Response'>
                                    <div>
                                        <pre style={{ textAlign: 'justify' }}>
                                            Body:
                                            {JSON.stringify(res.body, null, 4)}
                                        </pre>
                                        <pre style={{ textAlign: 'justify' }}>
                                            Response:
                                            {JSON.stringify(res.response, null, 4)}
                                        </pre>
                                    </div>
                                </ModalWrapper>
                                </td>
                                {/* <td>{res.response}</td> */}
                                <td>{res.method}</td>
                                <td>{JSON.stringify(res.body)}</td>
                                <td>{res.host}</td>
                                <td>{res.code}</td>
                                <td><ModalWrapper title="user Info" buttonTitle='User Info'>
                                    <pre style={{ textAlign: 'justify' }}>
                                        {JSON.stringify(res.userInfo, null, 4)}
                                    </pre>
                                </ModalWrapper>
                                </td>
                            </tr>
                        ])}
                    </tbody>
                </table>
            </div>
        </div>
    }

    function tabs() {
        const allTabs = [];
        function findTabs() {
            data.map(res => {
                if (res?.id && !allTabs.includes(res.id)) {
                    allTabs.push(res.id);
                }
            });
        };

        findTabs();

        return <ul className="nav nav-tabs">
            {allTabs.map((i) => (
                <li className="nav-item" key={i} onClick={() => setSelected(i)}>
                    <a className="nav-link" aria-current="page" href="#">{i}</a>
                </li>
            ))}
        </ul>
    }

    return (
        <div>
            {!data.length ? <div> <h4>Please ask the customer to enable 'Remote Debug' at the bottom of the SlidePanel from Dashboard</h4></div> : null}
            {(data.length > 0) && tabs()}
            {selected && debugTable()}
        </div>
    );
}

export default Debug;