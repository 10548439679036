import React, { useState, useEffect } from 'react';
import Expense from './Expense.js';
import ExpenseAdmin from './ExpenseAdmin.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { JsonEditor } from 'json-edit-react'
import C from './../../config.js';
import { AuthStore } from '../../store.js';
import { requestWithAuth } from '../../fetch.js';

// helpers
const loadImageAsBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};

const generatePDF = async (name, entries, isBase64, isSubView, callback) => {
    const margin = 52;

    function manipulateNotPrintDiv(add) {
        const hideBillRow = document.getElementsByClassName('notPrint');
        for (let item of hideBillRow) {
            (add) ? item.classList.add("hide") : item.classList.remove("hide")
        };
    }
    if (entries.length) {
        manipulateNotPrintDiv(true)
    }
    const pdfEl = document.getElementById('wrapper');
    const exp = document.getElementById('toDownload');
    const tableCanvas = await html2canvas(exp);
    const pdfSize = tableCanvas.width + margin;
    const doc = new jsPDF('p', 'pt', [pdfSize, pdfSize]);

    // Renders the HTML content to PDF with the defined margins
    await doc.html(pdfEl, {
        async callback(doc) {
            doc.addImage(tableCanvas, 'JPEG', 0, 0); // Position with margins and calculated size

            // Fetch and insert images into the PDF for each entry with a receipt
            for (let en of entries) {
                if (en?.images?.length) {

                    for (let im of en.images) {
                        try {
                            const imageUrl = isSubView ? `${api}/${im}` : URL.createObjectURL(im)
                            const imageBase64 = await loadImageAsBase64(imageUrl);

                            // Create a new Image element to get the original dimensions
                            const img = new Image();
                            img.src = imageBase64;

                            // Wait for the image to load to get the original dimensions
                            await new Promise((resolve) => {
                                img.onload = resolve;
                            });

                            const imgWidth = img.width;
                            const imgHeight = img.height;

                            // Define the maximum width and height for the image in the PDF within the margins
                            const maxWidth = doc.internal.pageSize.getWidth() - 2 * margin;
                            const maxHeight = doc.internal.pageSize.getHeight() - (160 + 2 * margin);  // Accounting for text space and margins

                            // Calculate the width and height to maintain the aspect ratio
                            let width = imgWidth;
                            let height = imgHeight;

                            if (width > maxWidth) {
                                const scaleFactor = maxWidth / width;
                                width = maxWidth;
                                height = height * scaleFactor;
                            }

                            if (height > maxHeight) {
                                const scaleFactor = maxHeight / height;
                                height = maxHeight;
                                width = width * scaleFactor;
                            }

                            // Add a new page for the receipt with margins applied
                            doc.addPage(); // Adding a new page for the receipt
                            doc.addImage(imageBase64, 'JPEG', margin, margin, width, height); // Position with margins and calculated size
                            doc.text(`Receipt for entry ${en.description} (amount: ${en.amount}€)`, margin, height + margin + 20);
                        } catch (error) {
                            console.error(`Failed to load image for ${en.description}:`, error);
                        }
                    }
                }
            }

            // Save the PDF after adding all the content and images
            if (isBase64 && callback) {
                const blobUrl = await doc.output('datauristring');
                callback(blobUrl)
            } else {
                await doc.save(C.getPdfName({ name, entries }));
            }
        },
    });
    if (entries.length) {
        manipulateNotPrintDiv()
    }
};

const ExpensesAdminConf = (props) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
        read()
    }, []);

    async function read() {
        const resJson = await requestWithAuth('expenses/config', 'GET', null, 'google')
        setSelected(resJson[0])
    }

    async function update(obj) {
        obj.newData._id = selected._id;
        const createRes = await requestWithAuth('expenses/config', 'PUT', obj.newData, 'google');
        window.toast(createRes.status == 200 ? "Expense Report Updated" : "Unauthorized access.");
    }

    const editableData = { ...selected };
    delete editableData._id;
    delete editableData.__v;

    return (<div>
        <pre>
            <JsonEditor
                data={editableData}
                onUpdate={update}
                maxWidth={'100%'}
            />
        </pre>
    </div>)
}

const Expenses = (props) => {
    const [selected, setSelected] = useState(0);
    const [infoBanner, setInfoBanner] = useState();
    const token = props.token

    useEffect(() => {
        if (token?.isSupervisor || token?.isExpenseAdmin) checkToDos()
    }, [token])

    async function checkToDos() {
        let loadedList = await read();
        // check if supervisor has any unsigned expense
        const testSupervisor = loadedList.filter(f => f.supervisorAssigned === token.email && !f.supervisorSignature)
        const testAdmin = loadedList.filter(f => !f.supervisorAssigned && !f.draft)
        const toUpdate = [];
        if (testSupervisor.length) {
            toUpdate.push(<p>As <b>Supervisor</b>, you have <b>{testSupervisor.length}</b> Expenses to  <a href="#" onClick={() => setSelected(3)}>review </a> </p>)
        }
        if (testAdmin.length) {
            toUpdate.push(<p>As <b>Admin</b>, there are <b>{testAdmin.length}</b> Expenses to <a href="#" onClick={() => setSelected(4)}>assign supervisor </a></p>)
        }
        setInfoBanner(toUpdate);
    }

    async function read(supervisors) {
        const resJson = await requestWithAuth(supervisors ? 'expenses/supervisors' : 'expenses', 'GET', null, 'google')
        return resJson;
    }

    async function create(id, formData) {
        const createRes = await requestWithAuth(`expenses/${id}`, 'POST', formData, 'google', { form: true });
        window.toast(createRes.status == 200 ? "Expense Report Created" : "Unauthorized access.");
    }

    if (!C.projects.expenses.active) {
        return <div><img src='https://www.wpassist.ca/wp-content/uploads/2021/03/wordpress-maintenance-mode.jpg' width='100%'></img>
        </div>
    }

    return (
        <div style={{ width: '90%', padding: '2%', margin: 'auto' }}>
            {token && selected > -1 ? <div>
                {infoBanner && <div className="alert alert-warning" role="alert">
                    {infoBanner.map(a => <div key={JSON.stringify(a)}>{a}</div>)}
                </div>}
                {(token.isSupervisor || token.isExpenseAdmin) ? <ul className="nav nav-tabs" style={{ float: 'right' }}>
                    {token.isSupervisor === true && <li className="nav-item" onClick={() => setSelected(3)}>
                        <a className={selected === 3 ? 'nav-link active' : 'nav-link'} href="#">Supervise</a>
                    </li>}
                    {token.isExpenseAdmin === true && <li className="nav-item" onClick={() => setSelected(4)}>
                        <a className={selected === 4 ? 'nav-link active' : 'nav-link'} href="#">Admin</a>
                    </li>}
                    {token.isExpenseAdmin === true && <li className="nav-item" onClick={() => setSelected(5)}>
                        <a className={selected === 5 ? 'nav-link active' : 'nav-link'} href="#">Admin Configuration</a>
                    </li>}
                </ul> : null}
                <ul className="nav nav-tabs">
                    <li className="nav-item" onClick={() => setSelected(0)}>
                        <a className={selected === 0 ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">Expense</a>
                    </li>
                    <li className="nav-item" onClick={() => setSelected(1)}>
                        <a className={selected === 1 ? 'nav-link active' : 'nav-link'} href="#">Draft</a>
                    </li>
                    <li className="nav-item" onClick={() => setSelected(2)}>
                        <a className={selected === 2 ? 'nav-link active' : 'nav-link'} href="#">History</a>
                    </li>
                </ul>

            </div> : null}

            {token && selected === 0 && <Expense read={read} user={{ email: token.email }} onCreate={create} generatePDF={generatePDF} />}
            {token && selected === 1 && <ExpenseAdmin read={read} view={'draft'} user={{ email: token.email }} token={token.token} generatePDF={generatePDF} />}
            {token && selected === 2 && <ExpenseAdmin read={read} view={'history'} user={{ email: token.email }} token={token.token} generatePDF={generatePDF} />}
            {token && selected === 3 && <ExpenseAdmin read={read} view={'supervisor'} user={{ email: token.email }} token={token.token} generatePDF={generatePDF} />}
            {token && selected === 4 && <ExpenseAdmin read={read} view={'admin'} user={{ email: token.email }} token={token.token} generatePDF={generatePDF} />}
            {token && selected === 5 && <ExpensesAdminConf user={{ email: token.email }} token={token.token} />}

        </div>
    )
};

const Wrapper = () => {
    const user = AuthStore.user('google');
    return (user ? <Expenses token={user} /> : null);
}

export default Wrapper