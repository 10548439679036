import React, {useState} from 'react';
import { AuthStore } from '../store.js';
import C from '../config.js';

const gTree = [{
    title: 'All',
    children: [
        // gravite
        {
            title: null,
            img: 'https://th.adzine.de/cH1GJKF7-js8NwcCxn3g7R_-keU=/1200x630/smart//uploads/logos/Logo_Tech-Finder_Gravite.jpg',
            url: (email) => `https://gravite.net`
        },
        // gmail
        {
            title: null,
            img: 'https://images.news18.com/ibnlive/uploads/2020/11/1604413203_gmail_logo.jpg',
            url: (email) => `https://mail.google.com/mail/u/?authuser=${email}`
        },
        // calendar
        {
            title: null,
            img: 'https://www.slashgear.com/wp-content/uploads/2020/07/google_calendar_main-1280x720.jpg',
            url: (email) => `https://calendar.google.com/calendar?authuser=${email}`
        },
        // drive
        {
            title: null,
            img: 'https://2.bp.blogspot.com/-3S2-Klcl0_A/UaU_Y_mv5cI/AAAAAAAAAVs/kmxg1G7W67U/s320/Logo+Google+Drive.JPG',
            url: (email) => `https://drive.google.com/drive?authuser=${email}`
        },
        // slack
        {
            title: null,
            img: 'https://i2.wp.com/wersm.com/wp-content/uploads/2019/01/wersm-new-slack-logo-featured.png?fit=1024%2C768&ssl=1',
            url: () => 'https://gravite-team.slack.com'
        },
        // bamboo
        {
            title: null,
            img: 'https://www.6connex.com/wp-content/uploads/bamboo-hr-logo.jpg',
            url: () => 'https://gravite.bamboohr.com/home/'
        },
        // dashboard
        {
            title: 'Dashboard',
            img: './dashboard.png',
            url: () => 'https://dashboard.gravite.net/'
        },
        // Udemy
        {
            title: null,
            img: 'https://about.udemy.com/wp-content/uploads/2016/07/about-default.png',
            url: () => 'https://gravite.udemy.com/'
        },
        // SDK
        {
            title: null,
            img: 'https://devimages-cdn.apple.com/wwdc-services/articles/images/0796345E-5C55-41C3-B6E8-7FEC41BAF9D4/2048.jpeg',
            url: () => 'https://aatkit.gitbook.io'
        },
        {
            title: 'Retro',
            img: 'https://www.reetro.io/images/retrospectives-guide-1.png',
            url: () => 'https://dev-retro.aatkit.com/'
        },
        // HR Documentation
        {
            title: 'HR Docu',
            img: 'https://www.insperity.com/wp-content/uploads/document_HR__1200x600-1024x538.png',
            url: () => 'https://addapptr-jira.atlassian.net/wiki/spaces/AA/overview'
        },
        // Confluence
        {
            title: null,
            img: 'https://clipground.com/images/confluence-logo-png-13.png',
            url: () => 'https://addapptr-jira.atlassian.net/wiki/home'
        },
        // Email
        {
            title: 'Email Signature Creator',
            img: 'https://www.wikihow.com/images/9/99/Sign-a-Cool-Signature-Step-14-Version-2.jpg',
            url: () => `${C.projects.core.client}/signature`
        },
        // Expense
        {
            title: 'Expense Report Creator',
            img: 'https://travelbank.com/wp-content/uploads/2020/06/taking-photo-of-receipt.png',
            url: () => `${C.projects.core.client}/expense`
        },
        // Last Pass
        {
            title: null,
            img: 'https://bpb-us-w2.wpmucdn.com/blogs.sjsu.edu/dist/5/118/files/2021/03/LastPass-Logo-header.jpg',
            url: () => 'https://lastpass.com/'
        },
        // AONIC shares
        {
            title: null,
            img: 'https://aonic.co/imager/uploads/10339/Aonic-yellow-BG-2_e64c9834eaf898a746cd960310ec9321.webp',
            url: () => 'https://portal.optioincentives.no/'
        }
    ]
},
{
    title: 'German employees',
    children: [
        // Mitarbeiter Angebote
        {
            title: null,
            img: 'https://www.aecocmedia.es/wp-content/uploads/2019/06/rebajas.jpg',
            url: () => 'https://gravite.mitarbeiterangebote.de/'
        },
        // gutschein
        {
            title: null,
            img: 'https://www.steuersparcard.de/images/SSC_MGS-Box.png',
            url: () => 'https://www.steuersparcard.de/'
        },
        // Datev
        {
            title: null,
            img: 'https://www.datev.de/web/de/media/tech_datev/layout_datev/images_datev/logo_datev/logo.png',
            url: () => 'https://apps.datev.de/ano/'
        },
        // Jobrad
        {
            title: null,
            img: 'https://www.jobrad.org/media/presse/pressebilder/gruender-vor-neuer-firmenzentrale.jpg',
            url: () => 'https://portal.jobrad.org/addapptr_gmbh/4AeKE7XuEe'
        },
        // Deutschlandticket 
        {
            title: null,
            img: 'https://stadtbus-dormagen.de/fileadmin/_processed_/1/c/csm_deutschlandticket-chip_5e5e6154ab.jpg',
            url: () => 'https://abo.ride-ticketing.de/app/login?partnerId=2c0491d3eb3af34daed890e9ee02b399'
        }
    ]
},
{
    title: 'French employees',
    children: [
        // gutschein
        {
            title: null,
            img: 'https://images.prismic.io/weareswile/e2743a13-e6cb-44db-ae32-9e53aaba06be_share_swile-app.png?auto=compress,format',
            url: () => 'https://directory.swile.co/signin?origin=https%3A%2F%2Fteam.swile.co%2Fwallets%3Flid%3[…]t=https%3A%2F%2Fsocial-api.swile.co%2Fapi%2Fv0%2Fmates%2Fme :slightly_smiling_face:'
        }
    ]
},
{
    title: 'KAM',
    children: [
        // Dashboard
        {
            title: 'Stage Dashboard',
            img: './dashboard.png',
            url: () => 'https://dashboard-stage.aatkit.com/'
        },
        // Mayo plot
        {
            title: 'Mayo Plot',
            img: 'https://www.pngall.com/wp-content/uploads/10/Line-Chart-Transparent.png',
            url: () => 'https://mayoplot-mayo2.aatkit.com/'
        },
        // OTRS
        {
            title: null,
            img: 'https://corporate.otrs.com/wp-content/uploads/2019/03/OTRS-Logo.jpg',
            url: () => 'https://otrs.addapptr.com/otrs/index.pl?'
        },
        // Admin RT
        {
            title: 'Unmappable Tool',
            img: 'https://www.pngkit.com/png/detail/212-2123388_maximize-revenue-growth-revenue-png.png',
            url: () => 'https://admintool-revenuetool.aatkit.com/'
        },
        // Admob Code generator
        {
            title: 'Auth Code generator',
            img: 'https://upload.wikimedia.org/wikipedia/commons/7/7f/Logo_de_Google_AdMob.png',
            url: () => 'https://login.addapptr.com/authcodegenerator'
        },
        {
            title: 'KAM Wiki',
            img: 'https://www.insperity.com/wp-content/uploads/document_HR__1200x600-1024x538.png',
            url: () => 'https://bitbucket.org/addapptr/ad-network-documentations/wiki/Home'
        },
        {
            title: null,
            img: 'https://i.imgur.com/DNM0srF.jpg',
            url: () => 'https://docs.google.com/spreadsheets/d/1XjXxwCmVqhtup910AMkf3vDqGGywUpJl/edit#gid=2037284843'
        },
        {
            title: '',
            img: 'https://mark.trademarkia.com/logo-images/whitepages/whitepages-86671777.jpg',
            url: () => `${C.projects.core.client}/`
        }
    ]
},
{
    title: 'BD',
    children: [
        {
            title: '',
            img: 'https://ctmaxs.com/wp-content/uploads/2017/04/hubspot-logo-1920.jpg',
            url: () => 'https://app-eu1.hubspot.com/contacts/27228726/objects/0-2/views/22401676/list'
        },
        {
            title: '',
            img: 'https://www.esic.edu/sites/default/files/rethink/febf1d78-rtb.jpg',
            url: () => 'https://dashboard-rtb.gravite.net/dashboard'
        },
        {
            title: '',
            img: 'https://mark.trademarkia.com/logo-images/whitepages/whitepages-86671777.jpg',
            url: () => `${C.projects.core.client}/`
        }
    ]
},
{
    title: 'DEV',
    children: [
        // dashboard
        {
            title: 'Test Dashboard',
            img: './dashboard.png',
            url: () => 'https://dashboard-test.aatkit.com/'
        },
        // JIRA
        {
            title: null,
            img: 'https://wac-cdn.atlassian.com/dam/jcr:48f73fa9-325e-4663-a743-daba2a0f1397/jira-social%20@2x.png',
            url: () => 'https://addapptr-jira.atlassian.net/jira/dashboards/last-visited'
        },
        // bitbucket
        {
            title: null,
            img: 'https://logowik.com/content/uploads/images/bitbucket9553.jpg',
            url: () => 'https://bitbucket.org/addapptr/workspace/overview'
        },
        // Core
        {
            title: null,
            img: 'https://cdn.dribbble.com/users/564122/screenshots/2226713/core_logo.jpg?compress=1&resize=800x600',
            url: () => `${C.projects.core.client}/`
        },
        // Kibana
        {
            title: null,
            img: 'https://banner2.cleanpng.com/20180825/fvz/kisspng-logo-kibana-elasticsearch-image-portable-network-g-12-2-16-dayjournal-blog-5b81d863177747.4775913715352361950961.jpg',
            url: () => "http://node01.elastic.monitoring.aatkit.com:5601/login?next=%2Fapp%2Fdiscover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:60000),time:(from:now-15h,to:now))&_a=(columns:!(syslog_message),filters:!(),grid:(columns:(container_id:(width:1257))),hideChart:!f,index:b062f836-43a7-4c09-a1f8-19ed00243908,interval:auto,query:(language:kuery,query:'container_id:%20041a263eff81c0227430acfe77fb482771e9b68c26637d9316145efe3cd9d0de'),sort:!(!('@timestamp',desc)))"
        },
        // Postman
        {
            title: null,
            img: 'https://media.glassdoor.com/companyupdate/o/1926052/postman-companyupdate-1592820738538.jpg',
            url: () => 'https://web.postman.co/'
        },
        // GCloud
        {
            title: null,
            img: 'https://i0.wp.com/www.techcrumble.net/wp-content/uploads/2018/06/google-cloud-platform.png?fit=917%2C426&ssl=1',
            url: (email) => `https://console.cloud.google.com?authuser=${email}`
        },
        // recaptcha
        {
            title: null,
            img: 'https://soltuin.com/wp-content/uploads/2018/12/google-recaptcha-logo.png',
            url: (email) => `https://www.google.com/u/2/recaptcha/admin/site/449344093?authuser=${email}`
        },
        // Solar winds
        {
            title: null,
            img: 'https://www.asiapacificsecuritymagazine.com/wp-content/uploads/2018/01/solarwinds-inc-logo900x900.jpg',
            url: (email) => `https://my.solarwinds.cloud/home?swicus_org_id=40933189095029760`
        },
    ]
}];

const Header = () => (
  <div className='team'>
    <div className="px-4 py-5 text-center cover">
      <h1 className="display-5 fw-bold text-body-emphasis text-top">Gravite Tools</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
          List of all the Gravite resources
        </p>
      </div>
    </div>
  </div>
)

const Projects = (user) => {
  const [efk, setEfk] = useState(null);
  return (
    gTree.map((l) => (
      <div className="container px-4 py-5" id="custom-cards" key={l.title}>
        <h2 className="pb-2 border-bottom">{l.title}</h2>
        <div className="row row-cols-1 row-cols-lg-4 align-items-stretch g-4 py-5">
          {l.children.map((child, index) => (
            <div className="col" key={child.url(user.user)}>
              <div
                onMouseEnter={() => setEfk(index)}
                onMouseLeave={() => setEfk(null)}
                className={`card card-cover overflow-hidden text-bg-dark rounded-4 shadow-lg ${efk === index ? 'zoomed' : ''}`}
                style={{ backgroundImage: `url(${child.img})`, backgroundSize: '120%', backgroundPosition: 'center', height: '230px', minWidth: '310px' }}
              >
                <a href={child.url(user.user)} target="_blank" className="btn btn-primary stretched-link" style={{ backgroundColor: child.img ? 'transparent' : 'blue', border: 'none', color: 'blueviolet', textShadow: '2px 2px 5px white' }}>
                  <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                    <h3 className="mt-5 mb-4 display-7 lh-1 fw-bold text-blue" style={{ background: '#ffffffab', color: 'black', border: '1px', borderRadius: '4px' }}>
                      {child.title}
                    </h3>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>)
    ))
}

const Porfolio = (props) => {
  return (
    <>
      <Header />
      <Projects user={props.user} />
    </>
  )
};

const Wrapper = () => {
    const user = AuthStore.user('google');
    return (user ? <Porfolio user={user} /> : null);
}

export default Wrapper
