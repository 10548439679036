import React, { useState } from 'react';
import { post } from '../fetch.js';
import C from '../config.js'

const Tests = () => {
    const [data, setData] = useState([]);
    const [banner, setBanner] = useState(null);

    // version Checker
    const [project, setProject] = useState(null);
    const [env, setEnv] = useState(null);

    function processText(text) {
        if (text.toLowerCase().includes('error')) {
            setBanner({
                type: 'danger',
                text: "Tests Failed"
            })
        } else {
            setBanner({
                type: 'success',
                text: "Tests Passed"
            })
        }
    }

    async function runTests() {
        const target = C.projects[project][env].backend;
        setData('Loading...');
        const info = await post(`${C.projects.testApi.prod}/run`, {
            project,
            target
        });
        processText(info.result);
        setData(info.result);
    }

    return (
        <div className="container">
            <div style={{ margin: '2%' }}>
                <h5>Tests Runner</h5>
                <form style={{ margin: '1%' }}>
                    {C.projects && (
                        <select className="form-select small" onChange={(e) => setProject(e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {Object.keys(C.projects).map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>)}
                    {project && (
                        <select className="form-select small" onChange={(e) => setEnv(e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {Object.keys(C.projects[project]).map((item) => <option key={item} value={item.backend}>{item}</option>)}
                        </select>)}
                    <button type="button" className="btn btn-primary" disabled={!env} onClick={() => runTests()}>Run</button>
                </form>

                {/* {versionInfo && <pre className={"alert alert-info"} role="alert">
                    {JSON.stringify(versionInfo, null, 4)}
                </pre>} */}
            </div>


            <div className='row' style={{ marginTop: "2%" }}>
                <h5>Output</h5>
                {banner && <div className={"alert alert-" + banner.type} role="alert">
                    TL;DR {banner.text}
                </div>}
                <pre className="output">{data || 'No output'}</pre>
            </div>

        </div>
    )
};

export default Tests