import React, { useState, useEffect } from 'react';
import C from '../config.js'
import { AuthStore } from '../store.js';
import { requestWithAuth } from '../fetch.js';

const address = {
    in: {
        name: null,
        street: "Große Reichenstraße 27",
        zip: 20457,
        city: "Hamburg",
        country: "Germany",
        legal: "Managing directors: Patrick Kollmann and Alexander von der Geest. Registered at Hamburg District Court, HRB 124705. The company's head office is situated in Hamburg. Gravite is an AddApptr GmbH brand.",
    },
    fr: {
        name: null,
        street: "83 Rue d'Aboukir",
        zip: 75002,
        city: "Paris",
        country: "France",
        legal: "Managing director: Alexander von der Geest. Registration with the Trade and Companies Register: 824485429 R.C.S.Paris. The company’s head office is situated in Hamburg. Gravite is an AddApptr GmbH brand.",
    }
}

const bannerList = [
    { name: 'None' },
    { name: 'Gravite Default', img: `${C.projects.core.api}/banner.png`, href: 'https://gravite.net/' },
    { name: 'DMEXCO', img: 'https://i.imgur.com/V2Iq41H.png', href: 'https://gravite.net/' },
    { name: 'Christmas', img: 'https://i.imgur.com/50cPg2S.png', href: 'https://gravite.net/' },
    { name: 'Moving', img: 'https://i.imgur.com/dleJX0K.png', href: 'https://gravite.net/' },
]

const Template = ({ name, position, email, phone, country, banner }) => {
    if (!country) return '';
    return `
  <div style="max-width: 620px;background-color: transparent; ">
  <table style="color: #222222; border-collapse: collapse;font-family: Helvetica, Arial, sans-serif;" border="0">
    <tbody>
      <tr style="height: 97.0938px;">
        <td style="width: 200px; font-size: 12px;">
          <p style="font-family: Helvetica,Arial,sans-serif; font-size: medium;"><span style="font-size: 18px;">${name}</span> <span style="font-size: 14px;"><br />${position}</span></p>
        </td>
        <td style="padding-left: 5px; font-size: 12px; width: 175.5px;"><img
            src=${C.projects.gravite.logo}
            alt="gravite logo" width="120" height="31" /></td>
      </tr>
    </tbody>
    <tbody>
      <tr style="height: 46px;">
        <td style="width: 200px; font-size: 12px; "><a style="color: #1155CC;"
            href="mailto:${email}" target="_blank" rel="noopener">${email}</a> <br /> ${phone}
          <br /> <strong> <a style="color: #1155CC;" href="http://www.gravite.net/" target="_blank" rel="noopener"
              data-saferedirecturl="https://www.google.com/url?q=http://www.gravite.net/&amp;source=gmail&amp;ust=1681984351931000&amp;usg=AOvVaw1VwmUsqroJKFzWPPtq9bXG">www.gravite.net</a>
          </strong></td>
        <td style="width: 175.5px; padding-left: 5px; font-size: 12px; ">${address[country].name || ''}<br />${address[country].street}<br />${address[country].zip}
          ${address[country].city}<br />${address[country].country}</td>
        <td style=" width: 50px;"><a href="https://www.linkedin.com/company/gravite-2023"
            target="_blank" rel="noopener"> <img
              src=${C.projects.linkedin.icon}
              alt="linkedin Logo" width="20" height="auto" /> </a></td>
      </tr>
    </tbody>
  </table>
  <br />
  <p style="font-size: 8px; font-family: Helvetica,Arial,sans-serif;">${address[country].legal}</p>
  ${C.emailBanner && banner && banner.name !== 'None' ? `<div style="object-fit: contain;"><a href=${banner.href} target="_blank"}>
    <img
      src=${banner.img} height="auto" style="max-width: 600px;"/></div></a>` : ''}
</div>
`;
}

const UploadModal = (props) => {
    const [show, setShow] = useState(props.show || false);
    const [image, setImage] = useState(null);

    const handleClose = () => {
        props.onCancel()
        setShow(false);
    }

    const onFinish = () => {
        props.onUpload(image)
        setShow(false);
    }

    const uploadImage = (img) => {
        if (img.size > 1024 * 1024 * 5) {
            alert("File is too big! (Max 5MB)");
        } else {
            setImage(img)
        }
    }

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Upload receipt (Only PNG and JPG/JPEG formats allowed)</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">

                                <div className="input-group mb-3">
                                    <input type="file" className="form-control" id="inputGroupFile01" name="upfile" accept=".png, .jpg, .jpeg" onChange={(event) => uploadImage(event.target.files[0])} />
                                </div>

                                <button type="button" className="btn btn-primary" data-mdb-dismiss="modal" onClick={onFinish} disabled={!image}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const SignagureForm = (props) => {
    const temp = Template('John', 'intern', 'john@gravite.net', '+123456789', 'uk', bannerList[0]);
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('in');
    const [renderedSignature, setRenderedSignature] = useState(temp);
    const [banner, setBanner] = useState(null);
    const [showUploadModal, setShowUploadModal] = useState(false)

    async function create(newItem) {
        const formData = new FormData();
        const extension = newItem.name.split('.').pop();
        const customFileName = `banner_signature_0.${extension}`;
        const renamedFile = new File([newItem], customFileName, { type: newItem.type });
        formData.append(`uploadExpense_banner`, renamedFile);
        const createRes = await requestWithAuth('signature', 'POST', formData, 'google', { form: true });
        window.toast(createRes.status == 200 ? "Default image updated" : "Unauthorized access.");
    }

    useEffect(() => {
        function capitalizeWords(str) {
            return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        }
        function getName() {
            let name = props.user.email.split('@')[0];
            name = name.replace('.', ' ');
            return capitalizeWords(name);
        }
        setName(getName())
        setEmail(props.user.email);
    }, []);

    useEffect(() => {
        const selBanner = bannerList.find(b => b.name === banner);
        setRenderedSignature(Template({ name, position, email, phone, country, banner: selBanner }))
    }, [name, position, email, phone, country, banner]);

    return (
        <div className="container" style={{ marginTop: '1rem' }}>
            <div className='row'>
                {props.user.isSignature ? <button type="button" style={{ marginBottom: '1rem', width: '10rem', float: 'right' }} className="btn btn-warning" data-mdb-dismiss="modal" onClick={() => setShowUploadModal(true)}> Upload new Default image </button> : null}
                <h5>Please fulfill the form to create a unique signature</h5>
            </div>
            <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Position" value={position} onChange={e => setPosition(e.target.value)} />
            </div>
            <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={country == 'in'} onChange={e => setCountry('in')} />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    International Template
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={country == 'fr'} onChange={e => setCountry('fr')} />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                    French Template
                </label>
            </div>

            <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                <label className="form-check-label" htmlFor="bannerSelect">
                    Banner
                </label>
                <select className="form-select" aria-label="Default select example" id="bannerSelect" defaultValue='0' onChange={e => setBanner(e.target.value)}>
                    {bannerList.map(b => <option key={b.name} value={b.name} >{b.name}</option>)}
                </select>
            </div>

            {showUploadModal && <UploadModal show={showUploadModal} onUpload={(o) => { create(o); setShowUploadModal(false) }} onCancel={() => null} />}

            <div className="row" style={{ marginTop: "4rem" }}>
                Please Copy the following Signature and paste it on your email Client <i>for Apple Mail please be sure to <b>UNCHECK "Always match my default message font"</b></i>
                <div className="shadow-lg p-3 mb-5 rounded" style={{ marginTop: "1rem" }} dangerouslySetInnerHTML={{ __html: renderedSignature }} />
            </div>
        </div >
    );
}

const Wrapper = () => {
    const user = AuthStore.user('google');
    return (user ? <SignagureForm user={user} /> : null);
}

export default Wrapper;
